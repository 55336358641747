/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'hand-index': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.75 1a.75.75 0 01.75.75V8a.5.5 0 001 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 101 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 001 0v-1h.342a1 1 0 01.995 1.1l-.271 2.715a2.5 2.5 0 01-.317.991l-1.395 2.442a.5.5 0 01-.434.252H6.035a.5.5 0 01-.416-.223l-1.433-2.15a1.5 1.5 0 01-.243-.666l-.345-3.105a.5.5 0 01.399-.546L5 8.11V9a.5.5 0 001 0V1.75A.75.75 0 016.75 1M8.5 4.466V1.75a1.75 1.75 0 10-3.5 0v5.34l-1.2.24a1.5 1.5 0 00-1.196 1.636l.345 3.106a2.5 2.5 0 00.405 1.11l1.433 2.15A1.5 1.5 0 006.035 16h6.385a1.5 1.5 0 001.302-.756l1.395-2.441a3.5 3.5 0 00.444-1.389l.271-2.715a2 2 0 00-1.99-2.199h-.581a5 5 0 00-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 00-.56-.642 2.6 2.6 0 00-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025"/>',
    },
});
